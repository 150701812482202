export const rto = [
    'GJ01 - RTO,AHMEDABAD',
    'GJ01W - WIAA,AHMEDABAD',
    'GJ02 - RTO,MEHSANA',
    'GJ03 - RTO,RAJKOT',
    'GJ04 - RTO, BHAVNAGAR',
    'GJ05 - RTO, SURAT',
    'GJ06 - RTO, VADODARA',
    'GJ07 - RTO, NADIAD',
    'GJ08 - RTO, BANASKANTHA',
    'GJ09 - RTO, SABARKANTHA',
    'GJ10 - RTO, JAMNAGAR',
    'GJ11 - RTO, JUNAGADH',
    'GJ12 - RTO, BHUJ - KACHCHH',
    'GJ13 - ASST.RTO, SURENDRANGAR',
    'GJ14 - ASST.RTO, AMRELI',
    'GJ15 - RTO, VALSAD',
    'GJ16 - ASST.RTO, BHARUCH',
    'GJ17 - RTO, GODHRA',
    'GJ18 - ASST.RTO, GANDHINAGAR',
    'GJ19 - ASST.RTO, BARDOLI',
    'GJ20 - ASST.RTO, DAHOD',
    'GJ21 - ASST.RTO, NAVSARI',
    'GJ22 - ASST.RTO, NARMADA',
    'GJ23 - ASST.RTO, ANAND',
    'GJ24 - ASST.RTO, PATAN',
    'GJ25 - ASST.RTO, PORBANDAR',
    'GJ26 - ASST.RTO, VYARA',
    'GJ27 - ASST.RTO, AHMEDABAD(EAST)',
    'GJ30 - ASST.RTO, AAHWA',
    'GJ31 - RTO ARVALLI(MODASA)',
    'GJ32 - RTO GIRSOMNATH(VERAVAL)',
    'GJ33 - RTO BOTAD',
    'GJ34 - RTO CHHOTAUDAIPUR',
    'GJ35 - RTO MAHISAGAR(LUNAVADA)',
    'GJ36 - RTO MORBI',
    'GJ37 - RTO DEVBHOOMI DWARKA(KHAMBHALIYA)',
    'GJ38 - ASST.RTO AHMEDABAD(RURAL), BAVLA',
    'AN - Andaman & Nicobar Islands',
    'AP - Andhra Pradesh',
    'AR - Arunachal Pradesh',
    'AS - Assam',
    'BR - Bihar',
    'CG - Chhattisgarh',
    'CH - Chandigarh',
    'DD - Daman and Diu',
    'DL - Delhi',
    'DN - Dadra & Nagar Haveli',
    'GA - Goa',
    'HP - Himachal Pradesh',
    'HR - Haryana',
    'JH - Jharkhand',
    'JK - Jammu & Kashmir',
    'KA - Karnataka',
    'KL - Kerala',
    'LD - Lakshadweep',
    'MH - Maharashtra',
    'ML - Meghalaya',
    'MN - Manipur',
    'MP - Madhya Pradesh',
    'MZ - Mizoram',
    'NL - Nagaland',
    'OR - Orissa',
    'PB - Punjab',
    'PY - Pondicherry',
    'RJ - Rajasthan',
    'SK - Sikkim',
    'TN - Tamil Nadu',
    'TR - Tripura',
    'UK - Uttarakhand',
    'UA - Uttarakhand',
    'UP - Uttar Pradesh',
    'WB - West Bengal',
    'Other'
]