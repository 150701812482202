import React, { useEffect } from 'react'
import FourImageSection from '../components/FourImageSection'

const OurTeam = () => {

  useEffect(() => {
    window.scrollTo(0, 0); 
}, []);


  return (
    <div>
      <section>
        <img width={'100%'} src="./images/banner-01.jpg" alt="" />
      </section>
      <section>
        <FourImageSection />
      </section>
    </div>
  )
}

export default OurTeam
